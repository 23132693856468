<template>
  <div v-if="fail">
    <div class="fail"><b>Invalid Url.</b></div>
  </div>
  <div v-if="!fail">
    <div id="display" :class="{ 'offline': !connected }">
      <div class="logo" v-if="!connected">
        <div class="svg">
          <svg fill="#000000" viewBox="0 0 100 100" data-name="Layer 1" id="Layer_1" xmlns="http://www.w3.org/2000/svg">
            <title />
            <path
              d="M80,21.08H20a2,2,0,0,0-2,2V67.81a2,2,0,0,0,2,2H34.14A8.22,8.22,0,0,0,33,74v2.88a2,2,0,0,0,2,2H65a2,2,0,0,0,2-2V74a8.22,8.22,0,0,0-1.18-4.23H80a2,2,0,0,0,2-2V23.08A2,2,0,0,0,80,21.08ZM63,74v.88H37V74a4.23,4.23,0,0,1,4.22-4.23H58.82A4.24,4.24,0,0,1,63,74Zm15-8.23H22V25.08H78Zm-36.43-6H58.65A10.07,10.07,0,0,0,68.71,49.3a10.17,10.17,0,0,0-9.88-9.58,10.58,10.58,0,0,0-21,1.57v.1l-.51.17a8.73,8.73,0,0,0-6.06,8.29V50A10.25,10.25,0,0,0,41.57,59.86Zm-3-14.48a12.31,12.31,0,0,0,.53,1.17,2,2,0,0,0,3.54-1.86,6.52,6.52,0,0,1-.75-3.28A6.58,6.58,0,0,1,55,41.63a2,2,0,0,0,.32,1.08l.05.09a2,2,0,0,0,1.69.91h1.35a6.27,6.27,0,0,1,6.3,5.78A6.08,6.08,0,0,1,63,54a6,6,0,0,1-4.39,1.88H41.57a6.22,6.22,0,0,1-6.29-5.93v-.11A4.79,4.79,0,0,1,38.53,45.38Z" />
          </svg>
        </div>
        <div class="text">
          Please wait ...
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Guacamole from 'guacamole-client'
import axios from "axios";


export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      fail: false,
      connected: false,
    }
  },
  methods: {
    createClient(tunnelUrl, token, connection_id, connection_protocol) {
      const tunnel = new Guacamole.ChainedTunnel(
        new Guacamole.WebSocketTunnel(tunnelUrl)
      )
      const tt = this;

      // Calculate optimal width/height for display
      var pixel_density = window.devicePixelRatio || 1;
      var optimal_dpi = pixel_density * 96;
      var optimal_width = window.innerWidth * pixel_density;
      var optimal_height = window.innerHeight * pixel_density;


      let con_proto = null;
      switch (connection_protocol) {
        case "rdp":
          con_proto = "g";
        break;
      }

      let scale = 1;

      console.log(con_proto);
      // Build base connect string
      var connectString =
        "token=" + encodeURIComponent(token)
        + "&GUAC_DATA_SOURCE=" + encodeURIComponent("mysql-shared")
        + "&GUAC_ID=" + encodeURIComponent(connection_id)
        + "&GUAC_TYPE=" + encodeURIComponent("c")
        + "&GUAC_WIDTH=" + Math.floor(optimal_width)
        + "&GUAC_HEIGHT=" + Math.floor(optimal_height)
        + "&GUAC_DPI=" + Math.floor(optimal_dpi);

      const client = new Guacamole.Client(tunnel)

      const dp = client.getDisplay();
      const el = dp.getElement()

      document.getElementById('display').appendChild(el)
      client.connect(connectString)

      var mouse = new Guacamole.Mouse(client.getDisplay().getElement());
      mouse.onEach(['mousedown', 'mouseup', 'mousemove'], function sendMouseEvent(e) {
        client.sendMouseState(e.state);
      });

      mouse.onmousemove = (state) => {
        updateMouseState(state);
      }

      let updateMouseState = (mouseState) => {
        mouseState.y = mouseState.y / scale;
        mouseState.x = mouseState.x / scale;
        client.sendMouseState(mouseState);
      }

      // Keyboard
      var keyboard = new Guacamole.Keyboard(document);
      keyboard.onkeydown = function (keysym) {
        client.sendKeyEvent(1, keysym);
      };

      keyboard.onkeyup = function (keysym) {
        client.sendKeyEvent(0, keysym);
      };

      window.onunload = function () {
        client.disconnect();
      }

      window.onresize = function () {
        // Calculate optimal width/height for display
        var pixel_density = window.devicePixelRatio || 1;
        var optimal_width = window.innerWidth * pixel_density;
        var optimal_height = window.innerHeight * pixel_density;
        tunnel.sendMessage('size', optimal_width, optimal_height)
      }

      client.onstatechange = function (s) {
        console.log("State:", s)
        switch (s) {
          case 3:
            console.log("Connected");
            // Calculate optimal width/height for display
            var pixel_density = window.devicePixelRatio || 1;
            var optimal_width = window.innerWidth * pixel_density;
            var optimal_height = window.innerHeight * pixel_density;
            tunnel.sendMessage('size', optimal_width, optimal_height)
            tt.connected = true;
            break;

          case 4:
            console.log("Closing");
            tt.connected = false;
            break;
        }
      }


    }
  },
  mounted() {
    const searchParams = new URLSearchParams(top.location.search);
    if (!searchParams.has("id")) {
      this.fail = true;
      return false;
    }
    axios.get("/api/credentials?key=" + searchParams.get("id"))
    .then((r) => {
      const { name = null, protocol = "rdp", token = null, id = null } = r.data;
      if(name) document.title = name;
      this.createClient("wss://vdi.run/websocket-tunnel", token, id, protocol)
    })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: black;
  
}
#displayOuter {
  background-color: purple !important;
}

body,
html {
  margin: 0px !important;
  padding: 0px !important;
  overflow: hidden !important;
  display: flex;
}

#display {
  height: 100vh;
  width: 100vw;
  display: flex;
  margin: auto;
  z-index: 1000 !important;
}

.logo {
  margin: auto;
}

.logo svg {
  height: 400px;
  width: 400px;
}

.logo .text {
  color: #fff;
  font-size: 1.2rem;
}

.logo svg path {
  fill: #0062f5 !important;
}

.offline {
  background-color: #323334;
}
.hostname {
  position: absolute;
  top: 25px;
  right: 25px;
  padding: 10px;
}
.fail {
  padding: 60px;
}

canvas {
  z-index: 1000 !important;
}
</style>
